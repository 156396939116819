.react-tel-input .form-control:focus {
  border-color: darkgrey;
  box-shadow: none;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: none;
  border-bottom: '.1px solid #900';
  border: 'none';
}
.react-tel-input .form-control.invalid-number {
  box-shadow: none;
  border-bottom: '.1px solid #900';
  border-color: darkgrey;
  border: 'none';
}
